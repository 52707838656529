import { template as template_6eb975bde95342f18b2c0e08826f5987 } from "@ember/template-compiler";
const FKText = template_6eb975bde95342f18b2c0e08826f5987(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
