import { template as template_724b060d1df24cd5a3cd8bcbc2876255 } from "@ember/template-compiler";
const FKLabel = template_724b060d1df24cd5a3cd8bcbc2876255(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
