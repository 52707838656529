import { template as template_5c57ce97f070416987d2c2b425cd0bde } from "@ember/template-compiler";
const SidebarSectionMessage = template_5c57ce97f070416987d2c2b425cd0bde(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
