import { template as template_1c7ba27d6b2c4da991269f92f8a7b53a } from "@ember/template-compiler";
const WelcomeHeader = template_1c7ba27d6b2c4da991269f92f8a7b53a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
